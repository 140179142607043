<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.sozlesme.liste.title") }}

      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.yeniKaydet')"
          :open-delay="500"
          placement="top"
          v-if="
            yetkiTuru == 1 ||
            (yetkiTuru == 2 && yetkiListesi.includes('YK-sozlesmeC100'))
          "
        >
          <el-button
            v-on:click="routeSozlesme('Sözleşme Tanımla')"
            size="mini"
            type="text"
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>

        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            style="margin-left: 15px !important"
            type="text"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>

    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.sozlesme.liste.loading')
          : $t('src.views.apps.sozlesme.liste.stateLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group
              size="small"
              v-on:change="handleChange($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
              :disabled="searchLoading"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-pen mdi-18px"
                  style="color: #13ce66; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '1'">{{
                    $t("src.views.apps.sozlesme.liste.activeOrder")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="0">
                <i
                  class="mdi mdi-pen mdi-18px"
                  style="color: #ec205f; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '0'">{{
                    $t("src.views.apps.sozlesme.liste.passiveOrder")
                  }}</label>
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <br v-if="!isDesktop" />
        <br v-if="!isDesktop" />
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul :class="isDesktop ? 'islemler' : 'islemler2'">
              <li
                class="duzenle"
                v-if="
                  yetkiTuru == '1' ||
                  (yetkiTuru == '2' &&
                    yetkiListesi.includes('YK-sozlesmeR100')) ||
                  yetkiListesi.includes('YK-sozlesmeU100')
                "
              >
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.sozlesme.liste.table.editContract") }}
              </li>
              <li v-if="selectIcon === '1'" class="sil">
                <i class="mdi mdi-trash-can"></i>
                {{ $t("src.views.apps.sozlesme.liste.table.setPassive") }}
              </li>
              <li v-else class="aktif">
                <i class="mdi mdi-play"></i>
                {{ $t("src.views.apps.sozlesme.liste.table.setActive") }}
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <el-table
        v-loading="searchLoading"
        :element-loading-text="'Sözleşme Aranıyor. Lütfen Bekleyin.'"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
        border
        stripe
        :data="sozlesmeList"
        style="width: 100%"
      >
        <el-table-column>
          <!--
                    <template slot="header">
                    </template>-->
          <el-table-column
            align="center"
            :label="$t('src.views.apps.genel.sira')"
            type="index"
            :index="indexMethod"
            width="auto"
          >
          </el-table-column>
        </el-table-column>

        <!--
                <el-table-column>
                    <template slot="header">
                    </template>
                    <el-table-column 
                        prop="sozlesmeID"
                        align="center"
                        :label='$t("src.views.apps.sozlesme.liste.table.id")'
                        width="110">
                    </el-table-column>
                </el-table-column>-->
        <!--
                <el-table-column>
                    <template slot="header">
                    </template>
                    <el-table-column
                        align="center"
                        prop="renkKodu"
                        :label='$t("src.views.apps.sozlesme.liste.table.renk")'
                        width="120">
                        <template slot-scope="scope">
                             <div style="display: flex; align-items: center; justify-content: center;">
                                <div :style="{ backgroundColor: scope.row.renkKodu, width: '30px', height: '30px' }"></div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>-->

        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="sozlesmeAra"
              :placeholder="
                $t('src.views.apps.sozlesme.liste.search.searchContractName')
              "
              v-model="sozlesmeBaslikAra"
              v-debounce:500ms="sozlesmeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="sozlesmeBaslik"
            :label="$t('src.views.apps.sozlesme.liste.table.sozlesmeAdi')"
            width="500"
          >
            <template slot-scope="props">
              <div>
                {{ props.row.sozlesmeBaslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="sozlesmeAra"
              placeholder="Sözleşme Kategori Ara"
              v-model="sozlesmeKategoriAra"
              v-debounce:500ms="sozlesmeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            prop="sozlesmeKategori"
            label="Sözleşme Kategori"
            :width="isDesktop ? '200px' : '180px'"
          >
            <template slot-scope="props">
              <div>
                {{ props.row.sozlesmeKategoriAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="sozlesmeAra"
              :placeholder="
                $t('src.views.apps.sozlesme.liste.search.searchPercent')
              "
              v-model="yuzdeDegerAra"
              v-debounce:500ms="sozlesmeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            align="center"
            prop="yuzdeDeger"
            :label="$t('src.views.apps.sozlesme.liste.table.yuzdeDeger')"
            :width="isDesktop ? 'auto' : '150px'"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="sozlesmeAra"
              :placeholder="
                $t('src.views.apps.sozlesme.liste.search.searchStartTime')
              "
              v-model="baslamaSuresiAra"
              v-debounce:500ms="sozlesmeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            align="center"
            prop="baslangicSuresi"
            :label="$t('src.views.apps.sozlesme.liste.table.baslangicSuresi')"
            :width="isDesktop ? 'auto' : '200px'"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="sozlesmeAra"
              :placeholder="
                $t('src.views.apps.sozlesme.liste.search.searchWorkTime')
              "
              v-model="isSuresiAra"
              v-debounce:500ms="sozlesmeAra"
              size="mini"
            ></el-input>
          </template>
          <el-table-column
            align="center"
            prop="isSuresi"
            :label="$t('src.views.apps.sozlesme.liste.table.isSuresi')"
            :width="isDesktop ? 'auto' : '200px'"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column fixed="right">
          <el-table-column
            :label="$t('src.views.apps.genel.islem')"
            align="right"
            width="150"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="
                  $t('src.views.apps.sozlesme.liste.table.editContract')
                "
                :open-delay="500"
                placement="bottom"
                v-if="
                  yetkiTuru == '1' ||
                  (yetkiTuru == '2' &&
                    yetkiListesi.includes('YK-sozlesmeR100')) ||
                  yetkiListesi.includes('YK-sozlesmeU100')
                "
              >
                <el-button
                  @click="updatePage(scope.row)"
                  type="text"
                  class="islem-button"
                  style="color: #f7ba2a; margin-left: 0px !important"
                  icon="mdi mdi-circle-edit-outline mdi-24px"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                :content="$t('src.views.apps.sozlesme.liste.table.setPassive')"
                :open-delay="500"
                v-if="selectIcon === '1'"
                placement="bottom"
              >
                <el-button
                  @click="changeSozlesmeDurum(scope.row, '0')"
                  class="buttonDel"
                  style="margin-left: 15px !important"
                  type="text"
                  icon="mdi mdi-trash-can mdi-24px"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                :content="$t('src.views.apps.sozlesme.liste.table.setActive')"
                :open-delay="500"
                v-if="selectIcon === '0'"
                placement="bottom"
              >
                <el-button
                  @click="changeSozlesmeDurum(scope.row, '1')"
                  type="text"
                  style="margin-left: 15px !important"
                  class="islem-button-play islem-button ml-15"
                  icon="mdi mdi-play mdi-24px"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div
        v-if="sozlesmeList.length == []"
        style="
          width: 100%;
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        {{ $t("src.views.apps.messages.noData") }}
      </div>

      <el-pagination
        background
        layout="sizes,prev, pager, next, jumper, total"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :page-sizes="[20, 50, 100]"
        :current-page.sync="page"
        :next-click.sync="page"
        :prev-click.sync="page"
        @next-click="pageChange"
        @prev-click="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import sozlesmeService from "../../../WSProvider/SozlesmeService";
import EventBus from "@/components/event-bus";
import notification from "../../../notification";
import functions from "../../../functions";

var moment = require("moment");

export default {
  name: "SozlesmeListesi",
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,

      // editDialogLoading: false,
      editDialog: false,
      editDialogData: {},

      yetkiListesi: [],
      yetkiTuru: "",

      sozlesmeList: [],

      selectIcon: "1",
      radio: "1",
      sozlesmeForm: {
        sozlesmeBaslik: "",
        sozlesmeKategori: "",
        yuzdeDeger: "",
        baslangicSuresi: "",
        isSuresi: "",
      },
      rulesSozlesme: {},

      sizes: 20,
      page: 0,
      postPage: 0,
      total: 4,

      sozlesmeBaslikAra: "",
      sozlesmeKategoriAra: "",
      yuzdeDegerAra: "",
      baslamaSuresiAra: "",
      isSuresiAra: "",
    };
  },

  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)
    }
    EventBus.$on("sozlesmelistesi", () => {
      this.refreshPage();
    });
  },

  mounted() {
    this.getSozlesmeListesi();
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },

  methods: {
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    updatePage(data) {
      //  console.log("updatePage girdi")
      this.$store.commit("changeSozlesmeUpdateData", data.sozlesmeID);
      functions.routeSayfa("Sözleşme Güncelle", this);
    },

    sozlesmeAra(pagination = false) {
      if (!pagination) {
        this.postPage = 0;
        this.page = 1;
        this.dateVisible = false;
      }

      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (
        this.sozlesmeBaslikAra.length > 0 ||
        this.sozlesmeKategoriAra.length > 0 ||
        this.yuzdeDegerAra.length > 0 ||
        this.baslamaSuresiAra.length > 0 ||
        this.isSuresiAra.length > 0
      ) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          sozlesmeService
            .sozlesmeFilter(
              this.sozlesmeBaslikAra,
              this.sozlesmeKategoriAra,
              this.yuzdeDegerAra,
              this.baslamaSuresiAra,
              this.isSuresiAra,
              this.selectIcon,
              this.postPage,
              this.sizes
            )
            .then((response) => {
              if (response.status == 200) {
                this.sozlesmeList = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.sozlesmeList = [];
                }
              } else {
                //notification.Status(503, this)
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        this.getSozlesmeListesi();
        // this.getSozlesmeSayisi();
      }
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      // this.getSozlesmeSayisi();
      this.getSozlesmeListesi();
    },

    // Guncelle(formName){
    //     this.$refs[formName].validate((valid, errors) => {
    //         if(valid){
    //             //adSoyad, mail, telefon, sozlesmeID
    //             this.$confirm("Sözleşmeyi güncellemek istediğinizden emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
    //                 confirmButtonText: this.$t("src.views.apps.genel.yes"),
    //                 cancelButtonText: this.$t("src.views.apps.genel.no"),
    //                 type: 'warning'
    //             }).then(() => {
    //                 try{
    //                     this.editDialogLoading = true;
    //                     sozlesmeService.sozlesmeGuncelle(this.sozlesmeForm.adSoyad, this.sozlesmeForm.eposta, this.sozlesmeForm.telefon, this.editDialogData.sozlesmeID).then(response => {
    //                         if(response.status){
    //                             this.vazgec(formName);
    //                             this.getSozlesmeListesi(this.selectIcon)
    //                         }
    //                         notification.Status("success", this, response.msg);
    //                         localStorage.setItem("userDataBGSurec", response.token);
    //                         this.editDialogLoading = false;
    //                     }).catch(err => {
    //                         if(err.responseJSON){
    //                             let error = err.responseJSON
    //                             if(error.status == 401){
    //                                 notification.Status(150, this);
    //                             }
    //                             else notification.Status("errorMsg", this, error.errMsg)
    //                         }else{
    //                             //notification.Status(503, this)
    //                         }
    //                         this.editDialogLoading = false;
    //                     })
    //                 }catch(e){
    //                     // notification.Status("danger", this, "An error occurred during the update process.")
    //                     this.editDialogLoading = false
    //                 }
    //             })
    //         }else{
    //             for(var k in errors){
    //                 errors[k].forEach((element) => {
    //                     notification.Status( "warning", this, element.message);
    //                 })
    //             }
    //             this.editDialogLoading = false;
    //         }
    //     })
    // },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    startUpdate() {
      this.sozlesmeForm.sozlesmeBaslik = this.editDialogData.sozlesmeBaslik;
      this.sozlesmeForm.yuzdeDeger = this.editDialogData.yuzdeDeger;
      this.sozlesmeForm.baslangicSuresi = this.editDialogData.baslangicSuresi;
      this.sozlesmeForm.isSuresi = this.editDialogData.isSuresi;

      // this.sozlesmeForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },

    changeSozlesmeDurum(selection, durum) {
      this.$confirm(
        "Sözleşmenin durumunu güncellemek istediğinizden emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          this.stateLoading = true;
          var item = { durum: durum };
          sozlesmeService
            .sozlesmeGuncelle(selection.sozlesmeID, item)
            .then((response) => {
              // console.log("changeSozlesmeDurum girdi")
              if (response.status == 200) {
                this.getSozlesmeListesi();
              }
              this.stateLoading = false;
              notification.Status("success", this, response.msg);
              localStorage.setItem("userDataBGSurec", response.token);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      });
    },

    getSozlesmeSayisi() {
      try {
        sozlesmeService
          .sozlesmeSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              //  console.log("getSozlesmeSayisi girdi")
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },

    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService
          .sozlesmeListesi(this.selectIcon, this.postPage, this.sizes)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.sozlesmeSayisi);
              response.data.forEach((el) => {
                /*el.dogumSaati = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('hh:mm:ss') : ''
                                el.dogumGunu = el.dogumTarihi != '0000-00-00 00:00:00' ? moment(el.dogumTarihi).format('YYYY-MM-DD') : ''
                                el.kayitSaati = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('hh:mm:ss') : ''
                                el.kayitGunu = el.kayitTarih != '0000-00-00 00:00:00' ? moment(el.kayitTarih).format('YYYY-MM-DD') : ''*/
              });
              this.sozlesmeList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    routeSozlesme(name, scope) {
      if (scope) {
        this.$store.commit("changeSozlesmeUpdateData", scope);
      }
      functions.routeSayfa(name, this);
    },

    indexMethod(index) {
      return (this.page - 1) * this.sizes + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      if (
        this.sozlesmeBaslikAra.length > 0 ||
        this.sozlesmeKategoriAra.length > 0 ||
        this.yuzdeDegerAra.length > 0 ||
        this.baslamaSuresiAra.length > 0 ||
        this.isSuresiAra.length > 0
      ) {
        this.sozlesmeAra(true);
      } else {
        this.getSozlesmeListesi();
      }
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getSozlesmeListesi();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
      this.sozlesmeBaslikAra = "";
      this.yuzdeDegerAra = "";
      this.baslamaSuresiAra = "";
      this.isSuresiAra = "";
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      if (
        this.sozlesmeBaslikAra.length > 0 ||
        this.sozlesmeKategoriAra.length > 0 ||
        this.yuzdeDegerAra.length > 0 ||
        this.baslamaSuresiAra.length > 0 ||
        this.isSuresiAra.length > 0
      ) {
        this.sozlesmeAra(true);
      } else {
        this.getSozlesmeListesi();
      }
    },
  },
};
</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;
}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
